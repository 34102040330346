import React, { Component } from "react";

class Hello extends Component {
  render() {
    return (
        <div className="container hello">
            <div className="row">
                <div className="col col-xs-12">
                    <picture>
                        <source type="image/webp" srcSet={`/assets/images/avatar.webp`} />
                        <source type="image/png" srcSet={`/assets/images/avatar.png`} />
                        <img src={`/assets/images/avatar.png`} alt="NuttTaro" title="NuttTaro" />
                    </picture>
                </div>
                <div className="col-sm-9 col-xs-12">
                    <div className="nes-balloon from-left">
                        <h2>Hello World. This is NuttTaro <i className="nes-icon like"></i></h2>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default Hello;
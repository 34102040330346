import React, { Component } from "react";
// import Xmas from "./Xmas";

class Footer extends Component {
  render() {
    return (
        <React.Fragment>
            <div className="container copyright">
                <div className="row">
                    <div className="col-xs-12">
                        <p>NuttTaro &copy; 2023.</p>
                    </div>
                </div>
            </div>
            {/* <Xmas /> */}


            <div className="bg-image">
                <picture>
                    <source
                        srcSet="/assets/images/bg@3x.webp"
                        type="image/webp"
                        media="(min-width: 1600px)" />
                    <source
                        srcSet="/assets/images/bg@2x.webp"
                        type="image/webp"
                        media="(min-width: 768px)" />
                    <source
                        srcSet="/assets/images/bg@1x.webp"
                        type="image/webp"
                        media="(min-width: 320px)" />

                    <source
                        srcSet="/assets/images/bg@3x.jpg"
                        type="image/jpeg"
                        media="(min-width: 1600px)" />
                    <source
                        srcSet="/assets/images/bg@2x.jpg"
                        type="image/jpeg"
                        media="(min-width: 768px)" />
                    <source
                        srcSet="/assets/images/bg@1x.jpg"
                        type="image/jpeg"
                        media="(min-width: 320px)" />

                    <img src="/assets/images/bg-xmas@1x.jpg" alt="Background 8 bit winter" title="Background 8 bit winter" />
                </picture>
            </div>

        </React.Fragment>
    );
  }
}

export default Footer;
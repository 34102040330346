import React, { Component } from "react";

class Sidebar extends Component {
    render() {
        return (
            <div className="sticky">
                <div className="nes-container with-title">
                    <h2 className="title">Things I Do</h2>
                    <div className="div">
                        <div className="lists">
                            <ul className="nes-list is-circle">
                                <li>PHP Developer</li>
                                <li>Wordpress Developer</li>
                                <li>Full Stack Developer</li>
                                <li>Front-End Developer</li>
                                <li>Shopify</li>
                                <li>SEO</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="nes-container with-title contact">
                    <h2 className="title">Contact</h2>
                    <div className="div">
                        <i className="nes-icon gmail is-small"></i>
                        <a href="&#77;&#97;&#73;&#76;&#84;&#111;&#58;&#110;&#117;&#116;&#116;&#116;&#97;&#114;&#111;&#64;&#111;&#117;&#116;&#108;&#111;&#111;&#107;&#46;&#99;&#111;&#109;">&#110;&#117;&#116;&#116;&#116;&#97;&#114;&#111;&#64;&#111;&#117;&#116;&#108;&#111;&#111;&#107;&#46;&#99;&#111;&#109;</a>
                    </div>
                </div>
                <div className="nes-container with-title socials">
                    <h2 className="title">Socials</h2>
                    <div className="div">
                        <div className="lists">
                            <a href="https://www.linkedin.com/in/nutttaro/" title="Linkedin" rel="noreferrer" target="_blank">
                                <i className="nes-icon linkedin"></i>
                            </a>
                            <a href="https://www.twitter.com/nutttaro" title="Twitter" rel="noreferrer" target="_blank">
                                <i className="nes-icon twitter"></i>
                            </a>
                            <a href="https://www.instagram.com/nutttaro" title="Instagram" rel="noreferrer" target="_blank">
                                <i className="nes-icon instagram"></i>
                            </a>
                            <a href="https://www.github.com/nutttaro" title="Github" rel="noreferrer" target="_blank">
                                <i className="nes-icon github"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sidebar;
import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
        <nav className="nav">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <i className="nes-octocat animate"></i>
                    </div>
                    <div className="col">
                        <h1><a href="https://nutttaro.com" title="NuttTaro">NuttTaro</a></h1>
                    </div>
                </div>
            </div>
        </nav>
    );
  }
}

export default Header;
import React, { Component } from "react";
import Header from "./components/Header";
import Hello from "./components/Hello";
import Home from "./pages/Home";
import Footer from "./components/Footer";

class Main extends Component {
    render() {
        return (
            <div>
                <Header />
                <Hello />
                <Home />
                <Footer />
            </div>
        );
    }
}

export default Main;
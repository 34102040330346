import React, { Component } from "react";

class Aboutme extends Component {
    render() {
        return (
            <div className="nes-container with-title">
                <h2 className="title">About me</h2>
                <div className="content">
                    <p>I'm a NuttTaro,</p>
                    <p>I'm a full-stack web developer located in Thailand.</p>
                    <p>I can use magic to bring a completely formed website into being out of nothing.</p>
                    <p>I specialize in working with PHP, HTML, CSS, and JavaScript, the building blocks of the web. My domains,
                        Linux servers, and certificates are all for my use.</p>
                    <p>Technologies that are open-source enable this procedure. I have years of experience creating unique
                        WordPress themes and plugins. I've fully embraced the Gutenberg editor and am capable of building
                        bespoke blocks from scratch to suit every imaginable requirement. I have made little contributions to
                        these and other open-source projects, and I want to continue doing so whenever I can.</p>
                </div>
            </div>
        );
    }
}

export default Aboutme;
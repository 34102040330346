import React, { Component } from "react";
import Aboutme from "./Aboutme";
import Skills from "./Skills";
import Experience from "./Experience";
import Portfolio from "./Portfolio";

class Content extends Component {
    render() {
        return (
            <React.Fragment>
                <Aboutme />
                <Skills />
                <Experience />
                <Portfolio />
            </React.Fragment>
        );
    }
}

export default Content;
import React, { Component } from "react";

class Portfolio extends Component {
    render() {
        return (
            <div className="nes-container with-title portfolio-list">
                <div className="title">
                    <div><i className="nes-icon heart"></i></div>
                    <div>
                        <h2>Portfolio</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/agrico-devices-min.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/agrico-devices-min.png" />
                                    <img src="/assets/images/portfolio/agrico-devices-min.png"
                                        alt="Agrico Plastiques" title="Agrico Plastiques" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    <a href="https://agricoplastiques.com" title="Agrico Plastiques" rel="noreferrer"  target="_blank">Agrico
                                        Plastiques</a>
                                </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>WooCommerce</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/realisations-passion-device.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/realisations-passion-device.png" />
                                    <img src="/assets/images/portfolio/realisations-passion-device.png"
                                        alt="Passion Monde" title="Passion Monde" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    <a href="https://www.passionmonde.com" title="Passion Monde" rel="noreferrer"  target="_blank">Passion
                                        Monde</a>
                                </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/belladerma-desktop.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/belladerma-desktop.png" />
                                    <img src="/assets/images/portfolio/belladerma-desktop.png" alt="Belladerma"
                                        title="Belladerma" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    <a href="https://www.belladerma.ca" title="Belladerma" rel="noreferrer"  target="_blank">Belladerma</a>
                                </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>Ruby on Rails</li>
                                        <li>Kirby CMS</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/viensvoirlesbaleines.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/viensvoirlesbaleines.png" />
                                    <img src="/assets/images/portfolio/viensvoirlesbaleines.png"
                                        alt="Viens voir les baleines" title="Viens voir les baleines" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    <a href="https://viensvoirlesbaleines.com" title="Viens voir les baleines" rel="noreferrer"  target="_blank">Viens
                                        voir les baleines</a>
                                </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/ovationtirescanada.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/ovationtirescanada.png" />
                                    <img src="/assets/images/portfolio/ovationtirescanada.png"
                                        alt="Ovation Tires Canada" title="Ovation Tires Canada" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    <a href="https://ovationtirescanada.ca" title="Ovation Tires Canada" rel="noreferrer"  target="_blank">Ovation
                                        Tires Canada</a>
                                </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/carrosseriedeboucherville.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/carrosseriedeboucherville.png" />
                                    <img src="/assets/images/portfolio/carrosseriedeboucherville.png"
                                        alt="Duval Carrosserie" title="Duval Carrosserie" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    <a href="https://carrosseriedeboucherville.com" title="Duval Carrosserie" rel="noreferrer"  target="_blank">Duval
                                        Carrosserie</a>
                                </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/excavationsgal.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/excavationsgal.png" />
                                    <img src="/assets/images/portfolio/excavationsgal.png"
                                        alt="G.A.L. Excavations" title="G.A.L. Excavations" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    <a href="https://www.excavationsgal.com" title="G.A.L. Excavations" rel="noreferrer"  target="_blank">G.A.L.
                                        Excavations</a>
                                </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp" srcSet="/assets/images/portfolio/dalisa.webp" />
                                    <source type="image/png" srcSet="/assets/images/portfolio/dalisa.png" />
                                    <img src="/assets/images/portfolio/dalisa.png" alt="Dalisa" title="Dalisa"
                                        loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    <a href="https://www.dalisa.ca" title="Dalisa" rel="noreferrer"  target="_blank">Dalisa</a>
                                </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>WooCommerce</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/realisations-voyagesbergeron-device_2.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/realisations-voyagesbergeron-device_2.png" />
                                    <img src="/assets/images/portfolio/realisations-voyagesbergeron-device_2.png"
                                        alt="Voyages Bergeron" title="Voyages Bergeron" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    <a href="https://www.voyagesbergeron.com" title="Voyages Bergeron" rel="noreferrer"  target="_blank">Voyages
                                        Bergeron</a>
                                </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>Symphony Framework</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/artdevivre-desktop.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/artdevivre-desktop.png" />
                                    <img src="/assets/images/portfolio/artdevivre-desktop.png"
                                        alt="L’art de vivre" title="L’art de vivre" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    L’art de vivre </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/realisations-oplant-device.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/realisations-oplant-device.png" />
                                    <img src="/assets/images/portfolio/realisations-oplant-device.png"
                                        alt="Oplant" title="Oplant" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    Oplant </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>Magento 2</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/realisations-oganic-device-1.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/realisations-oganic-device-1.png" />
                                    <img src="/assets/images/portfolio/realisations-oganic-device-1.png"
                                        alt="Organic Circle" title="Organic Circle" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    Organic Circle </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/realisations-duval-device-1.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/realisations-duval-device-1.png" />
                                    <img src="/assets/images/portfolio/realisations-duval-device-1.png"
                                        alt="Duval Occasion" title="Duval Occasion" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    Duval Occasion </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>WooCommerce</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/realisations-defi-device.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/realisations-defi-device.png" />
                                    <img src="/assets/images/portfolio/realisations-defi-device.png"
                                        alt="Défi Casse-Tête" title="Défi Casse-Tête" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    Défi Casse-Tête </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>WooCommerce</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/electrobac-cover.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/electrobac-cover.png" />
                                    <img src="/assets/images/portfolio/electrobac-cover.png" alt="Electrobac"
                                        title="Electrobac" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    Electrobac </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/dermablend-devices-min.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/dermablend-devices-min.png" />
                                    <img src="/assets/images/portfolio/dermablend-devices-min.png"
                                        alt="Dermablend Canada" title="Dermablend Canada" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    Dermablend Canada </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/cycle_tablet.webp" />
                                    <source type="image/png" srcSet="/assets/images/portfolio/cycle_tablet.png" />
                                    <img src="/assets/images/portfolio/cycle_tablet.png" alt="Cycle Performance"
                                        title="Cycle Performance" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    Cycle Performance </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>Magento 2</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/costarica-ipad.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/costarica-ipad.png" />
                                    <img src="/assets/images/portfolio/costarica-ipad.png" alt="ILT Costa Rica"
                                        title="ILT Costa Rica" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    ILT Costa Rica </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>Symfony Framework</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="portfolio-item">
                            <div className="image">
                                <picture>
                                    <source type="image/webp"
                                        srcSet="/assets/images/portfolio/realisations-ozone-device.webp" />
                                    <source type="image/png"
                                        srcSet="/assets/images/portfolio/realisations-ozone-device.png" />
                                    <img src="/assets/images/portfolio/realisations-ozone-device.png"
                                        alt="Ozone Wireless" title="Ozone Wireless" loading="lazy" />
                                </picture>
                            </div>
                            <div className="description">
                                <h3 className="title">
                                    Ozone Wireless </h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>WordPress</li>
                                        <li>PHP</li>
                                        <li>HTML, CSS, Javascript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-xs-12">
                        <div className="portfolio-item">
                            <div className="description">
                                <h3 className="title">and more than 50+ projects</h3>
                                <div className="lists">
                                    <ul className="nes-list is-circle">
                                        <li>Some of the remaining projects cannot be displayed because of the GDPR.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Portfolio;
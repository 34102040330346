import React, { Component } from "react";

class Skills extends Component {
    render() {
        return (
            <div className="nes-container with-title skill-list">
                <div className="title">
                    <div><i className="nes-icon star"></i></div>
                    <div>
                        <h2>Skills</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">PHP</div>
                    <div className="col-sm-8 col-xs-12">
                        <progress className="nes-progress is-success" value="95" max="100"></progress>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">Wordpress</div>
                    <div className="col-sm-8 col-xs-12">
                        <progress className="nes-progress is-success" value="95" max="100"></progress>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">Shopify</div>
                    <div className="col-sm-8 col-xs-12">
                        <progress className="nes-progress is-success" value="80" max="100"></progress>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">Front-End</div>
                    <div className="col-sm-8 col-xs-12">
                        <progress className="nes-progress is-success" value="80" max="100"></progress>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">Full Stack</div>
                    <div className="col-sm-8 col-xs-12">
                        <progress className="nes-progress is-success" value="85" max="100"></progress>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">SEO</div>
                    <div className="col-sm-8 col-xs-12">
                        <progress className="nes-progress is-success" value="75" max="100"></progress>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">Linux Server</div>
                    <div className="col-sm-8 col-xs-12">
                        <progress className="nes-progress is-success" value="80" max="100"></progress>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">React</div>
                    <div className="col-sm-8 col-xs-12">
                        <progress className="nes-progress is-success" value="60" max="100"></progress>
                    </div>
                </div>
            </div>
        );
    }
}

export default Skills;
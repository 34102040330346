import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import Content from "../components/Content";

class Home extends Component {
    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12 sidebar">
                        <Sidebar />
                    </div>
                    <div className="col-md-8 col-sm-12 col-xs-12 main-content">
                        <Content />
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;